import CLink from '@/CLink';
import styled from '@emotion/styled';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { PrimaryBtn } from './Buttons/PrimaryBtn';

const Member = styled.div`
    margin-bottom: 40px;

    :first-of-type {
        margin-top: 40px;
    }

    :last-of-type {
        margin-bottom: 0;
    }

    a {
        > h4 {
            transition: color 0.3s ease-in-out;

            > span {
                :hover,
                :focus {
                    color: ${({ theme }) => theme.colors.purple1};
                }
            }
        }

        .gatsby-image-wrapper {
            transition: box-shadow 0.3s ease-in-out;

            :hover,
            :focus {
                box-shadow: 0 0 0 1.5px ${({ theme }) => theme.colors.purple1};
            }
        }
    }

    h4 {
        margin: 0 0 16px;
        text-align: center;

        > span {
            color: ${({ theme }) => theme.colors.purple2};
            font-size: 1.5rem;
            line-height: 175.18%;
        }
    }

    > div {
        .gatsby-image-wrapper {
            border-radius: 15px;
            margin: 0 auto;
            opacity: 0.9999;
        }
    }

    @media (min-width: 1024px) {
        margin-bottom: 56px;

        :first-of-type {
            margin-top: 56px;
        }

        :last-of-type {
            margin-bottom: 0;
        }

        h4 {
            display: flex;
            margin-bottom: 8px;

            > span {
                text-align: left;
                flex-shrink: 0;
                overflow: hidden;
                max-width: 60ch;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            ::after {
                content: '';
                border-bottom: 2px solid ${({ theme }) => theme.colors.orange2};
                margin-left: 40px;
                width: 100%;
                height: 22px;
            }
        }

        > div {
            display: flex;
            align-items: stretch;

            .gatsby-image-wrapper {
                margin-right: 20px;
                flex-shrink: 0;
                margin-left: 0;
            }

            > div:last-of-type {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: column;

                > p {
                    margin: 0;
                }
            }
        }
    }

    @media (min-width: 1150px) {
        h4 > span {
            max-width: 66ch;
        }
    }

    @media (min-width: 1366px) {
        h4 > span {
            max-width: 75ch;
        }
    }
`;

type IntroListProps = {
    title: string;
    description: React.ReactNode;
    image: IGatsbyImageData;
    link?: string;
};

export const IntroList = ({ title, description, image, link }: IntroListProps) => {
    return (
        <Member>
            {link ? (
                <CLink to={link} data-type="button">
                    <h4>
                        <span>{title}</span>
                    </h4>
                </CLink>
            ) : (
                <h4>
                    <span>{title}</span>
                </h4>
            )}
            <div>
                {link ? (
                    <CLink to={link} data-type="button">
                        {' '}
                        <GatsbyImage image={image} alt={title} />{' '}
                    </CLink>
                ) : (
                    <GatsbyImage image={image} alt={title} />
                )}
                <div>
                    {description}
                    {link && (
                        <PrimaryBtn as={CLink} to={link} data-type="button">
                            Continue to read
                        </PrimaryBtn>
                    )}
                </div>
            </div>
        </Member>
    );
};
