import { Article } from '@/Article';
import CLink from '@/CLink';
import { CommonHero } from '@/CommonHero';
import { IntroList } from '@/IntroList';
import { info } from '@/layout/NavLinks';
import { Seo } from '@/layout/Seo';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData, ImageDataLike, getImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const team = [
    {
        title: 'Jose - Dental Hygienist',
        description: (
            <p>
                Jose loves making a difference in his patients’ smiles and helping to boost their
                self-esteem. He is a registered dental hygienist and enjoys the time he gets to
                spend with each patient and building relationships with them. <br /> <br />
                He attended Eastern Washington University in Cheney, WA, earning a Bachelor of
                Science degree. He started working in the dental field in 2016. Jose has always
                loved what making new smiles do for social, health, and overall general life in
                patients. <br /> <br />
                Jose loves working out, playing soccer, traveling to Mexico, and working with his
                family on their farm. Patients say he has a great personality and makes them feel at
                ease. Jose is bilingual, and speaking Spanish helps him to communicate with patients
                who don’t speak English.
            </p>
        ),
    },
    {
        title: 'Dakotah – Dental Hygienist ',
        description: (
            <p>
                Dakotah started working in the dental field in 2017 as a sterilization technician
                while also working towards her Bachelor of Science degree in dental hygiene at Lake
                Washington Institute of Technology (LWTech). She enjoys working alongside people who
                come to work every day with a positive attitude and who are passionate about what
                they do. As a dental hygienist, Dakotah loves that she has a practical way of
                helping people while also getting to build relationship with them. Dakotah is a
                great team player who works hard to help each patient have a positive experience at
                the dental office. <br /> <br />
                Dakotah and her husband, Vitaliy, have been married since 2019. Outside the office
                she can be found spending time outside with her family or working at her church.
            </p>
        ),
    },
    {
        title: 'Jacki – Office Manager / Financial Coordinator ',
        description: (
            <p>
                Jacki is the Financial/Patient Coordinator. She graduated from Clover Park Technical
                College and has worked for Dr. Nuttall since 2003. <br /> <br />
                She finds working in the dental field very enjoyable and rewarding and says it’s
                “awesome” to see the smiles on patient’s faces when they leave! Jacki says, “It does
                feel like we are making a major impact on someone’s life.” <br /> <br />
                When Jacki’s not at work, she has three great kids that keep her pretty busy! Her
                two teenagers and nine-year-old keep her on the ball. She goes to her kids’
                activities that include soccer and volleyball, plus she volunteers in her daughter’s
                class on Friday afternoons. Jacki enjoys cooking, camping, and hiking, and she loves
                to read!
            </p>
        ),
    },
    {
        title: 'Ashley – Schedule Coordinator',
        description: (
            <p>
                Ashley has been working in the dental field since 2015 and is a graduate of Bates
                Technical College. She enjoys the positive atmosphere around the office and loves
                helping patients achieve their ideal smile. <br /> <br />
                Outside the office, Ashley likes hanging out with friends, family, and her dog,
                Riggs.
            </p>
        ),
    },
    {
        title: 'Krystal – Dental Assistant',
        description: (
            <p>
                Krystal graduated from Pima Medical Institute for dental assisting in 2010 with
                honors. When it comes to her work, she enjoys helping patients take care of their
                smiles while helping them feel comfortable. She loves how everyone at the practice
                works well together, helping each other as needed and how great the work atmosphere
                is! She appreciates that Dr. Nuttall always has an upbeat, positive attitude. <br />{' '}
                <br />
                When not busy with work, Krystal loves spending time with Drew, her firefighter
                husband of 13 years, along with Nova, her oldest daughter who is in kindergarten and
                Aurora, her youngest daughter. All of them love Cheddar, their Mini Goldendoodle
                puppy and their cat, Bradley! In her spare time, you can find Krystal gardening,
                hiking, baking, exercising, doing crafts with her girls and spending time with her
                family.
            </p>
        ),
    },
    {
        title: 'Kamila – Dental Assistant',
        description: <p>Bio coming soon!</p>,
    },
    {
        title: 'Paula – Dental Assistant',
        description: <p>Bio coming soon!</p>,
    },
    {
        title: 'Gertie – Financial Coordinator',
        description: <p>Bio coming soon!</p>,
    },
    {
        title: 'Talli – Dental Assistant',
        description: <p>Bio coming soon!</p>,
    },
];

const MeetTeam: Page = () => {
    type DataTypes = {
        allFile: {
            nodes: {
                childImageSharp: ImageDataLike;
                name: string;
            }[];
        };
    };

    const data: DataTypes = useStaticQuery(graphql`
        query teamPics {
            allFile(
                filter: { relativeDirectory: { eq: "about/team" } }
                sort: { order: ASC, fields: name }
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 253, height: 303, layout: FIXED)
                    }
                    name
                }
            }
        }
    `);

    const teamWithPics = team.map(member => ({
        ...member,
        image: getImage(
            data.allFile.nodes.filter(
                person => person.name === member.title.split(' ')[0].toLowerCase()
            )[0].childImageSharp
        ) as IGatsbyImageData,
    }));

    return (
        <Fragment>
            <Seo
                title="Meet Our Team | Complete Dental Care Services"
                description="Meet our dedicated and passionate team at Nuttall Smiles, led by the best dentist in Auburn and surrounding areas. Reach out to us for inquiries."
            />
            <CommonHero />
            <Article big>
                <p>
                    Our friendly team of dental professionals is excited to meet you! We make your
                    concerns our top priority, and we work hard to make you feel at home when you
                    visit our practice, providing a positive, energetic atmosphere in which you can
                    feel relaxed and right at home. Please call Nuttall Smiles today at
                    <CLink to={info.phone.link}> {info.phone.text} </CLink>
                    to make your appointment with our dentist and learn more about our dental care
                    in Auburn, Washington. We look forward to helping you care for your smile!
                </p>

                {teamWithPics.map((member, i) => (
                    <IntroList key={i} {...member} />
                ))}
            </Article>
        </Fragment>
    );
};

export default MeetTeam;
